.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #ca042c;
}
.button:active {
  background-color: #980321;
}
@media (max-width: 1023px) {
  h1 {
    font-size: 40px;
  }
}
.section--two .area .pure.slim .part:last-child {
  padding-top: 10px;
}
.section--two .area .seam {
  margin-top: 16px;
  margin-bottom: 16px;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--one .area .tiny {
  width: 46%;
}
.section--one .area > .slim {
  width: 50%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.section--one .area > .slim .tiny {
  width: 92%;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--two .area .tiny {
  width: 46%;
}
.section--two .area > .slim {
  width: 50%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.section--two .area > .slim .tiny {
  width: 92%;
}
.section--one .area {
  width: 104%;
  margin-left: -2%;
}
.section--two .area {
  width: 104%;
  margin-left: -2%;
}
.section--two .area .seam {
  margin-left: calc(2% - 4px);
  margin-right: calc(2% - 4px);
  width: calc(96% + 8px);
}
.section--two .area .seam .part {
  width: 50%;
  padding-left: 4px;
  padding-right: 4px;
}
/*# sourceMappingURL=./screen-medium.css.map */